@import url('https://fonts.googleapis.com/css?family=Poppins');

:root {
  --delet-blue: #5271ff;
  --delet-pink: #EAEAEB;
  --delet-secondary: #B3B3B3;
  --delet-green: #00BA08;
  --status-purple: #C16EC8;
  --status-red: #E22626;
  --status-yellow: #FFD43E;
  --status-orange: #FFA011;
  --status-blue: #5765E7;
  --status-cyan: #57E7E7;
  --status-gray: #D9D9D9;
}

.bg-image {
  background-image: url('./assests/images/login-bg.png') !important;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.bg-image form {
  display: flex;
  flex-direction: column;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-login {
  background-color: rgba(255, 255, 255, 1);
  padding: 54px 35px;
  width: 430px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
}

.login-btn {
  background-color: #4067f9 !important;
  color: white !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin: auto;
}

.login-btn span {
  font-size: 16px;
}

.login-text {
  display: inline-block;
}

.brand-logo {
  padding-bottom: 65px;
}

.login-input {
  font-size: 40px;
}

.rotate {
  animation: rotateAnimation 1s infinite linear;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .main-login {
    width: 330px !important;
    background-size: contain !important;
  }

  .login-input {
    padding: 5px 12px !important;
  }

  .bg-image {
    background-position: left;
  }
}

@media (max-width: 1000px) {
  .bg-image {
    background-position: left;
  }
}

.full-screen-container {
  min-width: 100vw;
  min-height: 100vh;
}

.instruction-footer {
  height: 77px;
}

.text-delet-blue {
  color: var(--delet-blue) !important;
}

.instruction-img {
  width: 205px;
  height: 96px;
}

.bg-delet {
  background-color: var(--delet-blue) !important;
}

.bg-delet-pink {
  background-color: var(--delet-pink) !important;
}

.bg-delet-header {
  background-color: #2C2D31 !important;
}

.bg-delet-secondary {
  background-color: var(--delet-secondary) !important;
}

.bg-green {
  background-color: rgb(14, 155, 14) !important;
}

.bg-kit-green {
  background-color: rgb(0, 186, 8, 0.3);
  color: #00BA08;
  font-size: 12px;
  
  font-weight: 800;
}
.bg-kit-blue{
  background-color: rgb(82, 113, 255, 0.3);
  color: #5271ff;
  font-size: 12px;
  
  font-weight: 800;
}

.bg-kit-black{
  background-color: rgb(153, 153, 153, 0.3);
  color: #000000;
  font-size: 12px;
  
  font-weight: 800;
}

.bg-kit-grey {
  background-color: rgb(153, 153, 153, 0.3);
  color: #999999;
  font-size: 12px;
  
  font-weight: 800;
}

@media (min-width: 360px) and (max-width: 767px) {
.bg-kit-grey{
  background-color: rgb(153, 153, 153, 0.3);
  color:rgb(153, 153, 153);
  font-size: 12px;
  
  font-weight: 800;
}
.bg-kit-green{
  background-color: rgb(0, 186, 8, 0.3);
  color:rgb(0, 186, 8);
  font-size: 12px;
  
  font-weight: 800;
}
.bg-kit-blue{
  background-color: rgb(82, 113, 255, 0.3);
  color: #5271ff;
  font-size: 12px;
  
  font-weight: 800;
}

.bg-kit-black{
  background-color: rgb(153, 153, 153, 0.3);
  color: #000000;
  font-size: 12px;
  
  font-weight: 800;
}
}
@media (min-width: 768px) and (max-width: 1017px){

  .bg-kit-grey {
    background-color: rgb(153, 153, 153, 0.3);
    color: rgb(153, 153, 153, 0.0);
    font-size: 5px;
    
    font-weight: 800;
  }

  .bg-kit-green {
    background-color: rgb(0, 186, 8, 0.3);
    color: rgb(0, 186, 8, 0.0);
    font-size: 5px;
    
    font-weight: 800;
  }
}

@media (min-width: 768px) and (max-width: 1017px) {

  .bg-kit-grey {
    background-color: rgb(153, 153, 153, 0.3);
    color: #999999;
    font-size: 6px;
    
    font-weight: 800;
  }

  .bg-kit-green {
    background-color: rgb(0, 186, 8, 0.3);
    color: #00BA08;
    font-size: 7px;
    
    font-weight: 800;
  }
}

@media (min-width: 1018px) and (max-width: 1388px) {

  .bg-kit-grey {
    background-color: rgb(153, 153, 153, 0.3);
    color: #999999;
    font-size: 8px;
    
    font-weight: 800;
  }

  .bg-kit-green {
    background-color: rgb(0, 186, 8, 0.3);
    color: #00BA08;
    font-size: 9px;
    
    font-weight: 800;
  }
}

  .bg-kit-blue{
    background-color: rgb(82, 113, 255, 0.3);
    color: #5271ff;
    font-size: 7px;
    
    font-weight: 800;
  }

  .bg-kit-black{
    background-color: rgb(153, 153, 153, 0.3);
    color: #000000;
    font-size: 7px;
    
    font-weight: 800;
  }
  
  @media (min-width: 1018px) and (max-width: 1388px){

    .bg-kit-grey{
      background-color: rgb(153, 153, 153, 0.3);
      color:#999999;
      font-size: 8px;
      
      font-weight: 800;
    }
    .bg-kit-green{
      background-color: rgb(0, 186, 8, 0.3);
      color:#00BA08;
      font-size: 9px;
      
      font-weight: 800;
    }
    .bg-kit-blue{
      background-color: rgb(82, 113, 255, 0.3);
      color: #5271ff;
      font-size: 9px;
      
      font-weight: 800;
    }
    .bg-kit-black{
      background-color: rgb(153, 153, 153, 0.3);
      color: #000000;
      font-size: 9px;
      
      font-weight: 800;
    }
    }

.bg-red {
  background-color: rgb(218, 47, 9) !important;
}

.max-w-1024 {
  max-width: 1200px;
}

.logo {
  width: 130px;
  height: 30px;
}

.property-img-container {
  width: 100%;
  height: 270px;
}

.min-h-100vh {
  min-height: 100vh;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.number-icon {
  background: var(--delet-blue);
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 12px;
  width: 32px;
  height: 32px;
}

.number-icon span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 15px;
  font-weight: bold;
}

.sign-icon {
  border: var(--delet-blue) 1px solid;
  display: inline-block;
  text-align: center;
  line-height: 0px;
  border-radius: 50%;
  font-size: 12px;
  width: 20px;
  height: 20px;
}

.sign-icon span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 15px;
  font-weight: bold;
}

.border-b-delet {
  border-bottom: 3px solid var(--delet-blue) !important;
}

.border-delet {
  border: 3px solid var(--delet-blue) !important;
}

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

@media (min-width: 641px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: block !important;
  }

  .swiper-pagination {
    display: flex !important;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-right: 10px !important;
  }
}

.orders-list-container {
  overflow-y: auto;
}

.fs-7 {
  font-size: 0.95rem !important;
}


.fs-8 {
  font-size: 0.74rem !important;
}

.fs-9 {
  font-size: 0.5rem !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block !important;
  width: 100% !important;
}

.max-w-650 {
  max-width: 650px;
}

.so-option:hover {
  background-color: #ECEFFA !important;
}

.code-history-list {
  height: 60vh !important;
  overflow-y: scroll !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-primary-delet {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--delet-blue), var(--bs-bg-opacity)) !important;
}

.no-arrow::after {
  display: none !important;
}

.no-bg {
  background-color: transparent !important;
}

.no-bg:active,
.no-bg:focus,
.no-bg:hover {
  background-color: transparent !important;
  color: inherit !important;
}

.no-bg strong {
  color: inherit !important;
}

.status-tooltip .tooltip-inner {
  background-color: white;
  border: #d3d3d3 solid 1px;
  padding: 20px 12px;
  color: black;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
@media (max-width:755px) {
  .status-tooltip .tooltip-inner{
    font-size: 10px;
    padding: 6px 5px;
  }
}
.status-tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: white;
}
.status-tooltip .tooltip-arrow::before{
  display: none;
}

.router-status {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.py-05 {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}