.page-main-head {
  @media (max-width: 400px) {
    padding: 5px 0px 20px 5px;
  }
  @media (max-width: 480px) {
    padding: 5px 26px 20px 26px;
    // display: none;
  }
  .lead_search-control-group {
    margin: 0 0;
    position: relative;
    .form-control {
      border-radius: 4px;
      border-color: #66676b;
      width: 300px;
      padding: 0.75rem 2.2rem 0.75rem 2.5rem;
      z-index: 1;
      height: 45px;
      &::placeholder {
        color: #66676b;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 1%;
        text-transform: capitalize;
      }
      &:focus {
        border-color: #5d5fef;
        box-shadow: none;
      }
    }
    .search-btn {
      left: 11px;
    }
    .filter-button {
      position: absolute;
      left: 245px;
      top: 0;
    }
  }
  .export-button {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  .side-buttons {
    width: 40px !important;
    height: 40px !important;
  }
  height: 126px;
  h2 {
    font-size: 28.8px;
    font-weight: 700;
    color: #434449;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    color: #4c4d52;
  }
}
.banner-text {
  font-size: 14px;

  font-weight: 400;
  color: #4c4d52;
}

.banner-text-mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #676b7e;
}
.card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}
.left-td {
  text-align: left;
  padding: 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
}

.right-td {
  text-align: right;
  padding: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c4d52;
}

.rows {
  border-bottom: solid;
  border-color: rgba(153, 153, 153, 0.5);
  border-top: none;
  border-width: thin;
  border-right: none;
  border-left: none;
  margin: 10px 0;
}

.titles {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4c4d52;
}

.content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #979797;
}

.content-access {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c4d52;
}

.table-head {
  th {
    padding-bottom: 10px;
    font-size: 14px;

    font-weight: 600;
    line-height: 21px;
  }
  border-bottom: solid;
  border-top: none;
  border-width: thin;
  border-right: none;
  border-left: none;
  border-color: #e0e0e0;
  margin: 10px 0;
}

.table-b {
  td {
    .prop-address {
      font-size: 16px;
      font-weight: 700;
      color: #5271ff;
    }
    .prop-date {
      font-weight: 400;
      font-size: 14px;
      color: #979797;
    }
    padding-top: 20px !important;
    color: #4c4d52;
  }
}
.bottom-row {
  border-style: none;
}

.form-header {
  display: flex;
  // justify-content: center;
  align-items: center;
  justify-content: space-between;
  background-color: #eaeaeb;
  height: 60px;
  font-size: 14px;

  font-weight: 600;
  margin-bottom: 20px;
}

.header-row {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #979797;

  font-weight: 600;
  margin-right: 15px;
  margin-left: 15px;
  border-bottom: solid;
  border-top: none;
  border-width: thin;
  border-right: none;
  border-left: none;
  border-color: #e0e0e0;
}

.showings-header {
  font-size: 16px;
  font-weight: 600;
  color: #4c4d52;
  margin: 20px 0;
}

.lead_search-control-group {
  margin: 0 10px;
  position: relative;
  .search-btn {
    background-color: transparent;
    border: none;
    background-image: url("../../assests/images/search-icon.png");
    width: 20px;
    height: 20px;
    position: absolute;
    // left: 5px;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    top: 90%;
    margin-top: -26px;
    z-index: 1;
    background-color: #fff;
    color: #1c2023 !important;
    border-radius: 0 30px 30px 0;
    background-size: 16px;
  }
}
.cancel-filter-button {
  left: 300px;
}
.button-containers {
  display: flex;
  justify-content: end;
  width: 100%;
}
.lead_data {
  padding: 4px 0;
  margin: 0 8px;
  border-bottom: rgb(153, 153, 153, 0.5) solid 1px;
}
.side-buttons {
  width: 180px;
  height: 40px;
  background-color: rgb(153, 153, 153, 0.1) !important;
  border-color: #4c4d52 !important;
  color: #4c4d52 !important;

  font-weight: 700;
  margin: 0 10px;
}
.mantine-TextInput-wrapper input {
  height: 40px;
}
.filter-button-mobile {
  height: 40px;
  background-color: rgb(153, 153, 153, 0.1) !important;
  border-color: #4c4d52 !important;
  color: #4c4d52 !important;

  font-weight: 700;
  margin: 0 10px;
}
.end-button {
  width: 180px !important;
  height: 40px;
  background-color: #1c2023 !important;
  color: #ffffff !important;

  font-weight: 700;
  margin-left: 10px;
}
.clear-buttons {
  width: 100px !important;
  height: 40px;
  color: #999999 !important;

  font-size: 14px;
  margin-left: 10px;
}

.total-contacts {
  font-size: 12px;

  color: #4c4d52;
  font-weight: 700;
  margin-bottom: 10px;
}

.contact-info {
  max-width: 100%;
  text-decoration: none;
  font-size: 14px;
  color: #4c4d52;
}
.icon {
  margin-right: 5px;
}

.added-info {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #4c4d52 !important;
  text-align: start;
  padding: 15px 0 !important;
}
.id-image {
  width: 115px;
  height: 72.21px;
  border-radius: 6px;
  margin-right: 50px;
  margin-top: 6.5px;
}

@media (max-width: 755px) {
  .filter-popup {
    right: 3% !important;
  }
}
.filter-popup {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  top: calc(100% + 10px);
  right: 0;
  border-radius: 6px;
  border: 1px solid rgb(153, 153, 153, 0.5);
  padding: 25px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.date-inputs {
  width: 155px;
  height: 44px;
  border-radius: 6px;
  border: 1px solid rgb(153, 153, 153, 0.5);
  margin: 10px 0;

  font-size: 14px;
}

.date-inputs:focus {
  border: 2px solid red !important;
}
.datePickerWrapper {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  margin-top: 5px;
  margin-left: 10px;
  top: 35%;
  left: -40%;
}
.datePickerWrapperTo {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  margin-top: 5px;
  margin-left: 10px;
  top: 35%;
  left: 45%;
}

@media (max-width: 755px) {
  .datePickerWrapper {
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 35%;
    left: -5%;
  }
  .datePickerWrapperTo {
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 35%;
    left: 20%;
  }
}
.input-icon {
  position: relative;
}

.input-icon input {
  padding-left: 30px;
}

.input-icon svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-label {
  margin: 8px 0;

  font-size: 14px;
  font-weight: 600;
  color: #4c4d52;
}

.filter-button {
  background-color: #ffffff;
  color: #5271ff;
  border: none;

  font-size: 14px;
  font-weight: 600;
}
.close-button {
  background-color: #ffffff;
  color: #999999;
  border: none;

  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.outcome-dropdown {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* Add more styles as needed */
    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }
}

.custom-radio {
  &.form-check {
    padding-left: 0;
    margin-bottom: 0;

    .form-check-input {
      opacity: 0;

      &[type="radio"]:checked {
        + .form-check-label {
          background-color: #eeeeee;
        }
      }
    }
  }

  .form-check-label {
    padding: 1px 8px;
    color: #1f2327;
    font-size: 14px;
    cursor: pointer;
    border-radius: 3px;
  }
}
.pagination_component {
  display: flex;
  justify-content: center;
}
.link-w-ico {
  &.users-w-ico {
  }

  &.color-black {
    color: #2d2f36;
  }
}

.latest-showing {
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 18px;
  color: #4c4d52;
  font-weight: 400;
}
.date-time {
  font-weight: 400;
  font-size: 11px;
  color: #979797;

  @media (min-width: 720px) {
    font-size: 14px;
  }
}
.prop-add {
  font-weight: 400;
  font-size: 14px;
  color: #4c4d52;
  max-width: 200px;
}
.max-w-450 {
  max-width: 450px;
}

.leads-dtable {
  width: 100%;

  &.table {
    tbody {
      tr {
        td,
        td:first-child {
          text-align: left;
          padding-left: 20px;

          em {
            color: #72747b;
          }

          a {
            color: #1f2327;
            text-decoration: none;

            &[href^="mailto:"] {
              color: #5271ff;
            }
          }

          .future {
            background-color: #dbffcc !important;
          }

          .past {
            background-color: #fff8cc !important;
          }

          .custom-dropdown {
            background-color: rgba(146, 147, 153, 0.3);
            font-size: 11px;
            letter-spacing: 0.8px;
            line-height: 15px;
            color: #2d2f36;
            border-radius: 3px;
            padding: 2px 28px 2px 7px;
            display: inline-block;
            text-transform: uppercase;
            background-image: url("../../assests/images/downarrow.png");
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            cursor: pointer;

            &:after {
              content: "";
              height: 10px;
              position: absolute;
              bottom: -8px;
              width: 100%;
              left: 0;
            }

            ul {
              min-width: 190px;
              background-color: #fff;
              border-radius: 3px;
              padding: 8px;
              position: absolute;
              right: 0;
              top: 28px;
              margin-bottom: 0;
              display: none;
              padding-left: 10px;
              list-style: none;
              z-index: 1;

              li {
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.8px;
                line-height: 15px;
                color: #2d2f36;
                margin-bottom: 10px;
                cursor: pointer;
                text-align: left;
                display: flex;

                &.w-square {
                  padding-left: 36px;

                  &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -35px;
                    margin-right: 8px;
                    border-radius: 3px;
                  }

                  &.interested {
                    &:before {
                      background-color: #52ff58;
                      opacity: 0.3;
                    }
                  }

                  &.no-interest {
                    &:before {
                      background-color: #ff7277;
                      opacity: 0.3;
                    }
                  }

                  &.never-arrived {
                    &:before {
                      background-color: #e9d62a;
                      opacity: 0.3;
                    }
                  }

                  &.sent-application {
                    &:before {
                      background-color: #5271ff;
                      opacity: 0.3;
                    }
                  }

                  &.rescheduled {
                    &:before {
                      background-color: #929399;
                      opacity: 0.3;
                    }
                  }

                  &.leave-this {
                    &:before {
                      background-color: rgba(146, 147, 153, 0.3);
                      content: "x";
                      color: #858588;
                      text-align: center;
                      line-height: 20px;
                    }
                  }
                }

                button {
                  background-color: rgba(82, 113, 255, 0.3);
                  font-size: 11px;
                  letter-spacing: 0.8px;
                  line-height: 15px;
                  border-radius: 3px;
                  border: none;
                  text-transform: uppercase;
                  color: #2d2f36;
                  width: 100%;
                  text-align: center;
                }
              }
            }

            &:hover {
              ul {
                display: block;
              }
            }
          }

          .notes-btn {
            letter-spacing: 0.8px;
            line-height: 15px;
            display: block;
            text-transform: uppercase;
            position: absolute;
            bottom: 10px;
            left: 180px;
            background-color: transparent;
            padding: 0;
            border: none;
          }
        }

        td:last-child {
          text-align: right;
        }
      }
    }
  }
}

.modal-title {
  font-size: 1.4rem;
  font-weight: bold;
}
