@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* variables */
$font-family-base: "Poppins", sans-serif;

// Font sizes
$font-size-base: 1rem;
$h1-size: 2.5rem;
$h2-size: 2rem;
$h3-size: 1.75rem;
$table-header: 1.125rem;
$sidebar-text: 1rem;
$body-text: 0.875rem;
$small-text: 0.688rem;

$xs-text: 0.675rem;
/* COLORS  */
$primary-color: #5d5fef;
$secondary-color: #d8d8d8;
$color-black: #2c2d31;
$color-gray: #4c4d52;
$color-green: #0aba08;
$color-red: #ff0004;
$color-white: #ffffff;
$color-purple: #8280ff;

// Status Colors
$status-purple: #c16ec8;
$status-yellow: #ffe000;
$status-orange: #ff9d00;
$status-blue: #5765e7;
$status-cyan: #57e7e7;
$status-purple: #9747ff;

$role-badge-colors: (
  admin: $primary-color,
  agent: $color-gray,
);

$role-status-colors: (
  active: $color-green,
  pending: $color-red,
  revoked: $color-red,
);

$status-colors: (
  "missed": $status-orange,
  "show": $color-green,
  "rescheduled": $status-purple,
  "cancelled": $color-red,
  "scheduled": $color-purple,
  "arrived": $status-blue,
  "assigned": $status-cyan,
  "pending": $secondary-color,
);

$circle-colors: (
  completed: $color-green,
  returned: $status-purple,
  cancelled: $color-red,
  in-progress: $status-yellow,
  shipped: $status-orange,
  arrived: $status-blue,
  assigned: $status-cyan,
  pending: $secondary-color,
);

$action-colors: (
  "LOCK": $color-red,
  "UNLOCK": $color-green,
);

$property-status: (
  active: $color-green,
  inactive: $color-red,
  lease-agreement-sent: $status-orange,
  application-received: $status-blue,
  sold: $color-black,
  rented: $status-purple,
);
