@import "~bootstrap/scss/bootstrap";
@import "variables";

* {
  font-family: "Poppins", sans-serif !important;
}

video {
  border-radius: 10px;
}

h1 {
  font-size: $h1-size;
  font-weight: bold;
}

h2 {
  font-size: $h2-size;
}

th,
.table-h {
  font-size: $sidebar-text;
  font-weight: 600 !important;
  color: #4c4d52 !important;
}

p,
span {
  font-size: $body-text;
}

.small-text {
  font-size: $small-text;
  font-weight: 400;

  @media screen and (min-width: 768px) {
    font-size: $body-text;
  }
}

.xs-text {
  font-size: $xs-text;
}

.link-copied {
  background: rgba($color-green, 0.25);
  position: absolute;
  left: calc(100% - 10px);
  top: calc(100%);
}

.search-btn {
  background-color: transparent !important;
  border: none;
  position: absolute;
  background-repeat: no-repeat;
  z-index: 1;
  top: 18px;
  right: 10px;
}

.search-icon {
  width: 15px;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0 0 0 216px;

    &.main-left {
      padding: 0 0 0 26px;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }

  .hamburger-icon {
    height: 20px;
    width: 20px;
    max-width: 20px;
    padding-top: 4px;
  }

  &.no-margin-left {
    margin-left: 0;
    margin-right: 0;
  }
}

.color-gray {
  color: $color-gray;
}

.w-content {
  width: fit-content !important;
}

#email-tooltip .tooltip-inner {
  max-width: none !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.h-content {
  height: fit-content;
}

.sidebar {
  background-color: #24262b;
  height: 100vh;
  width: 216px;
  transition: all 0.5s;

  @media (min-width: 768px) {
    flex: 0 0 250px;
  }

  .btn-close {
    display: none;
  }

  @media (max-width: 767px) {
    transform: translateX(-250px);
    width: 0;
    position: fixed;
    left: 13px;
    top: 0;
    z-index: 99;

    .btn-close {
      position: absolute;
      right: 0;
      opacity: 1;
      background: none;
      padding: 5px 15px;
      width: 20px;
      height: 20px;
      display: block;

      &:focus {
        box-shadow: none;
      }

      svg {
        fill: #fff;
      }
    }
  }

  .logo {
    padding: 32px 30px;
    background-color: $color-black;
    transition: all 0.5s;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      a,
      button.btn.btn-primary {
        color: #fff;
        font-size: 15px;
        line-height: 20px;
        text-decoration: none;
        width: 100%;
        display: block;
        background-color: #24262b;
        margin-bottom: 1px;
        position: relative;
        transition: all 0.5s;
        text-align: left;

        .link-icon {
          position: absolute;
          left: 20px;
          top: 12px;
          transition: all 0.5s;
          // height: 20px;
          // width: 22px;
          background-repeat: no-repeat;
          background-size: cover;

          // svg {
          //   fill: #fff;
          // }

          &.properties-ico {
            background-image: url("assests/images/property.png");
          }

          &.leads-ico {
            background-image: url("assests/images/leads.png");
          }

          &.showings-ico {
            background-image: url("assests/images/showing.png");
          }

          &.cameras-ico {
            background-image: url("assests/images/camera.png");
            height: 25px;
          }

          &.settings-ico {
            background-image: url("assests/images/setting.png");
            height: 22px;
          }

          &.logout-ico {
            background-image: url("assests/images/leads.png");
          }
        }

        &:hover,
        &.active {
          .properties-ico {
            background-image: url("assests/images/property-hover.png");
          }

          .leads-ico {
            background-image: url("assests/images/leads-hover.png");
          }

          .showings-ico {
            background-image: url("assests/images/showing-hover.png");
          }

          .cameras-ico {
            background-image: url("assests/images/camera-hover.png");
          }

          .settings-ico {
            background-image: url("assests/images/setting-hover.png");
          }

          .logout-ico {
            background-image: url("assests/images/leads-hover.png");
          }
        }

        &:hover,
        &.active {
          background-color: #f0f2ff26;

          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 1%;
          text-transform: capitalize;
        }

        &.active {
          .date-picker-wrapper {
            display: block;
          }
          &::before {
            content: "";
            position: absolute;
            width: 5px;
            height: 100%;
            left: 0;
            z-index: 1000;
            background: #5271ff;
            border-radius: 0 5px 5px 0;
          }
        }
      }

      button.btn.btn-primary {
        border-radius: 0;
        border: none;
      }
    }
  }

  .side-bar-bottom {
    .side-bar-bottom-row {
      // margin-left: 17px;
      // margin-right: 5px;
      // padding: 0 10px;
      // margin-bottom: 5px;

      .link {
        background-color: rgba(255, 255, 255, 0.05);
        display: block;
        padding: 15px 20px;
        text-align: center;
        text-decoration: none;
        flex: 1;
        // margin: 0 5px;
        border-radius: 3px;

        &:hover {
          background-color: #66676b;
        }

        .link-icon {
          margin-bottom: 8px;
        }

        span {
          display: block;
          color: #ffffff;

          &.link-text {
            // font-size: 11px;
            // line-height: 15px;
            // text-transform: uppercase;

            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 1%;
            text-transform: capitalize;
          }
        }
      }
    }

    .user-info {
      padding-left: 10px;
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: #24262b;

      // &:hover {
      //   cursor: pointer;
      //   background-color: #66676b;
      // }

      img {
        border-radius: 0 5px 5px 0;
      }

      &.custom-border-top {
        border-top: 1px solid #bdbebf;
      }

      .user-info-left {
        padding-left: 4px;
        flex: 0 0 50px;
        max-width: 50px;
      }

      .user-info-middle {
        padding-right: 8px;
        padding-left: 2px;

        .user-info-name {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1%;
          color: #ffffff;
          display: block;
        }

        .user-info-email {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 1%;
          color: #ffffff;
          display: block;
        }
      }

      .user-info-right {
        flex: 0 0 18px;
        max-width: 18px;
        // background-color: #444444;
        display: flex;
        height: 56px;
        align-items: center;
        justify-content: center;
        align-self: center;
      }
    }
  }

  &.sidebar-active {
    width: 50px;

    .sidebar-top {
      overflow: hidden;
    }

    @media (min-width: 768px) {
      flex: 0 0 50px;
    }

    @media (max-width: 767px) {
      transform: translateX(0px);
      width: 250px;
      left: 0;
    }

    ul {
      li {
        .link-text {
          font-size: 0;

          @media (max-width: 767px) {
            font-size: 15px;
          }
        }
      }
    }

    .logo {
      padding: 32px 30px 32px 18px;
    }

    .side-bar-bottom {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }
  }
}

@media (max-width: 1200px) {
  .no-wrap-small-screen {
    white-space: nowrap;
  }
}

.form-group-border {
  border-bottom: 1px solid #e9e9e9;
}

.checkbox-wrap {
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  padding: 15px;
}

.mobile-header {
  @media (min-width: 768px) {
    background-color: #2b2b2b;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .mobile-header-wrap {
    @media (max-width: 767px) {
      display: flex;
      background-color: $color-black;
      margin-left: 0px;
      margin-right: 0px;
      padding: 10px 15px;

      .logo {
        padding: 4px 20px;
      }
    }

    @media (max-width: 767px) {
      .hamburger-icon {
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.panel-expand-control {
  width: 40px;
  position: absolute;
  background-color: transparent;
  font-size: 5px;

  @media (min-width: 768px) {
    right: -20px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    // padding-left: 5px;
  }

  @media (max-width: 767px) {
    position: relative;
    right: 0;
    width: 30px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: 100px;
  }

  .expand-button {
    display: flex;
    // align-items: center;
    // justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #5d5fef;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #4143a7;
    }
  }

  .tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8);
    color: transparent;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 10px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.share-calendar:hover {
  --bs-bg-opacity: 1 !important;
}

@each $status, $color in $circle-colors {
  .circle-#{$status} {
    background-color: $color;
  }
}

.booking-option {
  &::before {
    content: "";
    display: inline-block !important;
    width: 5px;
    height: 5px;
    border-radius: 100%;
  }

  @each $status, $color in $status-colors {
    &.#{$status}::before {
      background: $color;
    }
  }
}

.notifications-btn-container {
  max-height: 60vh;
  overflow-y: scroll;
  width: 35vw;
  @media (max-width: 576px) {
    width: 90vw;
  }
}

.notifications-counter {
  top: -10px;
  right: -10px;
  background: $primary-color;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $small-text;

  @media screen and (min-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: $body-text;
  }
}

@each $status, $color in $status-colors {
  .showing-status-#{$status} {
    border-color: $color !important;
    background-color: rgba($color, 0.15);

    &::before {
      background: $color;
    }
  }
}

@each $action, $color in $action-colors {
  .action-#{$action} {
    color: $color !important;
    background-color: rgba($color, 0.15);

    &::before {
      background: $color;
    }
  }
}

@each $status, $color in $property-status {
  .property-#{$status} {
    border-color: $color !important;
    background-color: rgba($color, 0.15);

    &::before {
      background: $color;
    }
  }
}

.mw-select {
  max-width: 100px;
}

.property-status-options-menu {
  width: 200px;
}

.id-image-w {
  width: 115px;
}

.id-image-h {
  height: 73px;
}

.role-badge {
  @each $role, $color in $role-badge-colors {
    &.#{$role} {
      background: rgba($color, 0.15);
      border-color: $color !important;
      color: $color;
    }
  }
}

.role-status {
  @each $status, $color in $role-status-colors {
    &.#{$status} {
      background: rgba($color, 0.15);
      color: $color;
    }
  }
}

.notification-icon-container {
  width: 50px;
  height: 50px;
}

.icon-button {
  width: 24px;
  height: 24px;
}

@media (min-width: 768px) {
  .icon-button {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 992px) {
  .icon-button {
    width: 32px;
    height: 32px;
  }
}

li.nav-item button.nav-link {
  font-size: $small-text !important;
  font-weight: normal !important;
  &.active {
    font-weight: 700 !important;
  }

  @media screen and (min-width: 768px) {
    font-size: $body-text !important;
  }
}

.btn {
  font-size: $small-text !important;
  @media screen and (min-width: 768px) {
    font-size: $body-text !important;
  }
}

.no-property-img {
  width: 179px;
  height: 179px;

  @media screen and (min-width: 1024px) {
    width: 280px;
    height: 280px;
  }
}
